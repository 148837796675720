import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import NotificationToast from "../../components/Helpers/NotificationToast";
import * as Yup from "yup";
import { Tab } from "@headlessui/react";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import SettingsSelect from "../Forms/SettingsSelect";
import SettingsTextInput from "../Forms/SettingsTextInput";
import SettingsDatePicker from "../Forms/SettingsDatePicker";
import SettingsSwitch from "../Forms/SettingsSwitch";
import SubmitButton from "../Forms/SubmitButton";
import SettingsCounter from "../Forms/SettingsCounter";
import AppLoader from "../Helpers/AppLoader";
import PhoneInput from "react-phone-number-input";

import CustomButton from "../Helpers/CustomButton";

export default function ApplicationForm() {
    const dispatch = useDispatch();
    const { url } = useParams();
    const [loading, setLoading] = useState(false);
    const [fileUploadInformation, setFileUploadInformation] = useState([]);
    const [unitTypes, setUnitTypes] = useState([]);
    const [initialValues, setInitialValues] = useState({ terms: false });
    // const [initialValues, setInitialValues] = useState({lease_surance: 1});
    const [properties, setProperties] = useState([]);
    // const { current_booking } = useSelector((state) => state.booking);
    const [current_booking, setCurrentBooking] = useState({});
    const [selectedIndex, setSelectedIndex] = useState(0);

    const [bankList, setBankList] = useState([]);

    const showEmploymentInfo = false;
    const showAffordability = false;
    const showNOK = false;

    const myRefAp1 = useRef(null);
    const myRefAp1Emp = useRef(null);
    const myRefAp1Aff = useRef(null);
    const myRefAp2 = useRef(null);
    const myRefAp1Bnk = useRef(null);
    const myRefAp1NOK = useRef(null);
    const myRefAp2Aff = useRef(null);
    const myRefAp2Bnk = useRef(null);
    const myRefTerms = useRef(null);
    const myRefAp2Prem = useRef(null);
    const myRefDocs = useRef(null);

    useEffect(async () => {
        setLoading(true);
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}properties`,
        })
            .then((response) => {
                setProperties(response.data);
            })
            .catch((error) => {
                alert("properties failed");
            });

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}paystack_banks`,
        })
            .then((response) => {
                let banks = response.data.data;
                let bankList = [];

                Object.keys(banks).forEach((key) => {
                    bankList.push({ key: banks[key].name, value: banks[key].name, label: banks[key].name, code: banks[key].code });
                });

                setBankList(bankList);
            })
            .catch((error) => {
                alert("Bank failed");
            });

        if (url) {
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}booking_url/${url}`,
            })
                .then((response) => {
                    setCurrentBooking(response.data);

                    getUnitTypes(response.data.property_id);

                    setInitialValues({
                        name: response.data.user.name,
                        surname: response.data.user.surname !== null ? response.data.user.surname : "",
                        email: response.data.user.email,
                        cell: response.data.user.cell !== "" ? "+" + response.data.user.cell : "",
                        title: response.data.user.profile && response.data.user.profile.title !== null ? response.data.user.profile.title : "",
                        nationality: response.data.user.profile && response.data.user.profile.nationality !== null ? response.data.user.profile.nationality : "",
                        id_number: response.data.user.profile && response.data.user.profile.id_number !== null ? response.data.user.profile.id_number : "",
                        date_of_birth: response.data.user.profile && response.data.user.profile.date_of_birth !== null ? response.data.user.profile.date_of_birth : "",

                        car: response.data.user.profile ? response.data.user.profile.car : "",
                        occupation: response.data.user.profile ? response.data.user.profile.occupation : "",
                        home_number: response.data.user.profile && response.data.user.profile.home_number ? "+" + response.data.user.profile.home_number : "",
                        employer: response.data.user.profile ? response.data.user.profile.employer : "",
                        employer_address: response.data.user.profile ? response.data.user.profile.employer_address : "",
                        employer_suburb: response.data.user.profile ? response.data.user.profile.employer_suburb : "",
                        employer_city: response.data.user.profile ? response.data.user.profile.employer_city : "",
                        employer_province: response.data.user.profile ? response.data.user.profile.employer_province : "",
                        employer_postal: response.data.user.profile ? response.data.user.profile.employer_postal : "",
                        employer_registration: response.data.user.profile ? response.data.user.profile.employer_registration : "",
                        employer_it: response.data.user.profile ? response.data.user.profile.employer_it : "",
                        employer_contact: response.data.user.profile && response.data.user.profile.employer_contact ? "+" + response.data.user.profile.employer_contact : "",
                        self_employed: response.data.user.profile ? response.data.user.profile.self_employed : 0,

                        banking_bank: response.data.user.profile ? response.data.user.profile.banking_bank : "",
                        banking_account_type: response.data.user.profile ? response.data.user.profile.banking_account_type : "",
                        banking_branch: response.data.user.profile ? response.data.user.profile.banking_branch : "",
                        banking_bank_code: "",
                        banking_branch_code: "",
                        banking_account_number: response.data.user.profile ? response.data.user.profile.banking_account_number : "",
                        banking_account_holder: response.data.user.profile ? response.data.user.profile.banking_account_holder : "",
                        criminal_record: response.data.user.profile ? response.data.user.profile.criminal_record : "",
                        other: response.data.user.profile ? response.data.user.profile.other : "",

                        company_application: response.data.company_application ? response.data.company_application : 0,
                        joint_application: response.data.joint_application,
                        joint_application_title: response.data.user.joint_application ? response.data.user.joint_application.title : "",
                        joint_application_name: response.data.user.joint_application ? response.data.user.joint_application.name : "",
                        joint_application_surname: response.data.user.joint_application ? response.data.user.joint_application.surname : "",
                        joint_application_email: response.data.user.joint_application ? response.data.user.joint_application.email : "",
                        joint_application_id_number: response.data.user.joint_application ? response.data.user.joint_application.id_number : "",
                        joint_application_nationality: response.data.user.joint_application ? response.data.user.joint_application.nationality : "",
                        joint_application_cell: response.data.user.joint_application && response.data.user.joint_application.cell ? "+" + response.data.user.joint_application.cell : "",
                        joint_application_date_of_birth: response.data.user.joint_application ? response.data.user.joint_application.date_of_birth : "",
                        joint_application_occupation: response.data.user.joint_application ? response.data.user.joint_application.occupation : "",
                        joint_application_employer: response.data.user.joint_application ? response.data.user.joint_application.employer : "",

                        joint_application_gross_salary: response.data.user.joint_application ? response.data.user.joint_application.gross_salary : "",
                        joint_application_other_income: response.data.user.joint_application ? response.data.user.joint_application.other_income : "",
                        joint_application_net_income: response.data.user.joint_application ? response.data.user.joint_application.net_income : "",
                        joint_application_rent: response.data.user.joint_application ? response.data.user.joint_application.rent : "",
                        joint_application_car_repayments: response.data.user.joint_application ? response.data.user.joint_application.car_repayments : "",
                        joint_application_loan_repayments: response.data.user.joint_application ? response.data.user.joint_application.loan_repayments : "",
                        joint_application_card_repayments: response.data.user.joint_application ? response.data.user.joint_application.card_repayments : "",
                        joint_application_groceries_costs: response.data.user.joint_application ? response.data.user.joint_application.groceries_costs : "",
                        joint_application_fuel_costs: response.data.user.joint_application ? response.data.user.joint_application.fuel_costs : "",
                        joint_application_school_fees: response.data.user.joint_application ? response.data.user.joint_application.school_fees : "",
                        joint_application_medical_aid: response.data.user.joint_application ? response.data.user.joint_application.medical_aid : "",
                        joint_application_total_monthly: response.data.user.joint_application ? response.data.user.joint_application.total_monthly : "",
                        joint_application_available_income: response.data.user.joint_application ? response.data.user.joint_application.available_income : "",
                        joint_application_other: response.data.user.joint_application ? response.data.user.joint_application.other : "",

                        joint_application_banking_bank: response.data.user.joint_application ? response.data.user.joint_application.banking_bank : "",
                        joint_application_banking_account_type: response.data.user.joint_application ? response.data.user.joint_application.banking_account_type : "",
                        joint_application_banking_branch: response.data.user.joint_application ? response.data.user.joint_application.banking_branch : "",
                        joint_application_banking_branch_code: "",
                        joint_application_banking_bank_code: "",
                        joint_application_banking_account_number: response.data.user.joint_application ? response.data.user.joint_application.banking_account_number : "",
                        joint_application_banking_account_holder: response.data.user.joint_application ? response.data.user.joint_application.banking_account_holder : "",

                        joint_application_current_address: response.data.user.joint_application ? response.data.user.joint_application.current_address : "",
                        joint_application_current_suburb: response.data.user.joint_application ? response.data.user.joint_application.current_suburb : "",
                        joint_application_current_city: response.data.user.joint_application ? response.data.user.joint_application.current_city : "",
                        joint_application_current_postal: response.data.user.joint_application ? response.data.user.joint_application.current_postal : "",
                        joint_application_current_province: response.data.user.joint_application ? response.data.user.joint_application.current_province : "",

                        property_id: response.data.property_id,
                        // unit_type: response.data.unit ? response.data.unit.id : "",
                        unit_type: response.data.unit_type ? response.data.unit_type.id : "",

                        occupy_unit: response.data.user.premises ? response.data.user.premises.occupy_unit : "",
                        parking: response.data.user.premises ? parseInt(response.data.user.premises.parking) : "",
                        // viewed_unit: response.data.premises ? response.data.premises.viewed_unit : "",
                        occupants_over_10: response.data.user.premises ? response.data.user.premises.occupants_over_10 : "",
                        occupants: response.data.user.premises ? response.data.user.premises.occupants : 0,
                        referral: response.data.user.premises ? response.data.user.premises.referral : "",
                        // assisted_by: response.data.premises ? response.data.premises.assisted_by : "",

                        invoice_monthly_total: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].invoice_monthly_total : "",
                        invoice_monthly_hide: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].invoice_monthly_hide : "",
                        parking_fee: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].parking_fee : "",
                        monthly_rental: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].monthly_rental : "",
                        monthly_rental_wording: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].monthly_rental_wording : "",
                        admin_fee: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].admin_fee : "",
                        deposit_price: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].deposit_price : "",
                        lease_surance: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].lease_surance : "",
                        booking_fee_price: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].booking_fee_price : "",
                        key_deposit: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].key_deposit : "",
                        carport: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].carport : "",
                        special_wording: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].special_wording : "",
                        effluent: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].effluent : "",
                        special_deduction: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].special_deduction : "",
                        refuse: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].refuse : "",
                        lease_start: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].lease_start : "",
                        lease_end: response.data.rates[response.data.rates.length - 1] ? response.data.rates[response.data.rates.length - 1].lease_end : "",

                        kin_title: response.data.user.next_of_kin && response.data.user.next_of_kin.title !== null ? response.data.user.next_of_kin.title : "",
                        kin_name: response.data.user.next_of_kin && response.data.user.next_of_kin.name !== null ? response.data.user.next_of_kin.name : "",
                        kin_surname: response.data.user.next_of_kin && response.data.user.next_of_kin.surname !== null ? response.data.user.next_of_kin.surname : "",
                        kin_relationship: response.data.user.next_of_kin && response.data.user.next_of_kin.relationship !== null ? response.data.user.next_of_kin.relationship : "",
                        kin_contact: response.data.user.next_of_kin && response.data.user.next_of_kin.contact !== null ? "+" + response.data.user.next_of_kin.contact : "",
                        kin_email: response.data.user.next_of_kin && response.data.user.next_of_kin.email !== null ? response.data.user.next_of_kin.email : "",
                        kin_address: response.data.user.next_of_kin && response.data.user.next_of_kin.address !== null ? response.data.user.next_of_kin.address : "",
                        kin_id_number: response.data.user.next_of_kin && response.data.user.next_of_kin.id_number !== null ? response.data.user.next_of_kin.id_number : "",
                        kin_suburb: response.data.user.next_of_kin && response.data.user.next_of_kin.suburb !== null ? response.data.user.next_of_kin.suburb : "",
                        kin_postal: response.data.user.next_of_kin && response.data.user.next_of_kin.postal !== null ? response.data.user.next_of_kin.postal : "",
                        kin_city: response.data.user.next_of_kin && response.data.user.next_of_kin.city !== null ? response.data.user.next_of_kin.city : "",
                        kin_tel_contact: response.data.user.next_of_kin && response.data.user.next_of_kin.tel_contact !== null ? "+" + response.data.user.next_of_kin.tel_contact : "",
                        kin_work_contact: response.data.user.next_of_kin && response.data.user.next_of_kin.work_contact !== null ? "+" + response.data.user.next_of_kin.work_contact : "",

                        gross_salary: response.data.user.affordability ? response.data.user.affordability.gross_salary : "",
                        other_income: response.data.user.affordability ? response.data.user.affordability.other_income : "",
                        net_income: response.data.user.affordability ? response.data.user.affordability.net_income : "",
                        rent: response.data.user.affordability ? response.data.user.affordability.rent : "",
                        car_repayments: response.data.user.affordability ? response.data.user.affordability.car_repayments : "",
                        loan_repayments: response.data.user.affordability ? response.data.user.affordability.loan_repayments : "",
                        card_repayments: response.data.user.affordability ? response.data.user.affordability.card_repayments : "",
                        groceries_costs: response.data.user.affordability ? response.data.user.affordability.groceries_costs : "",
                        fuel_costs: response.data.user.affordability ? response.data.user.affordability.fuel_costs : "",
                        school_fees: response.data.user.affordability ? response.data.user.affordability.school_fees : "",
                        medical_aid: response.data.user.affordability ? response.data.user.affordability.medical_aid : "",
                        total_monthly: response.data.user.affordability ? response.data.user.affordability.total_monthly : "",
                        available_income: response.data.user.affordability ? response.data.user.affordability.available_income : "",
                        other: response.data.user.affordability ? response.data.user.affordability.other : "",
                        terms: false,
                    });

                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    // window.location.href = "/404";
                });
        } else {
            setLoading(false);
        }
    }, [setProperties, setInitialValues]);

    if (loading === true) return <AppLoader />;

    async function getUnitTypes(id) {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}unit_types/${id}`,
        }).then((response) => {
            setUnitTypes(response.data);
        });
    }

    function formatToFloat(value) {
        if (value) {
            return parseFloat(value.replace(/,/g, ""));
        } else {
            return value;
        }
    }

    function availableIncome(income, outcome) {
        var money_in = formatToFloat(income);
        var money_out = formatToFloat(outcome);

        return money_in - money_out;
    }

    if (
        current_booking &&
        current_booking.rates &&
        current_booking.rates.length > 0 &&
        current_booking.rates[current_booking.rates.length - 1].signed_lease_at !== "" &&
        current_booking.rates[current_booking.rates.length - 1].signed_lease_at !== null
    ) {
        return (
            <main className="mt-14">
                <div className="max-w-7xl mx-auto pb-2 px-4">
                    <h2>Your lease has been signed, no futher updates to your information are allowed.</h2>
                </div>
            </main>
        );
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize="true"
            validateOnChange={true}
            validateOnBlur={true}
            validationSchema={Yup.object().shape({
                property_id: Yup.number().required("desired building"),
                unit_type: Yup.number().required("type of unit"),
                title: Yup.number().required("title for Applicant One"),
                name: Yup.string().required("name for Applicant One"),
                surname: Yup.string().required("surname for Applicant One"),
                email: Yup.string().required("email for Applicant One"),
                cell: Yup.number().required("phone number for Applicant One"),
                nationality: Yup.string().required("nationality for Applicant One"),
                date_of_birth: Yup.string().required("date of birth for Applicant One"),
                id_number: Yup.string().required("ID or passport number for Applicant One"),
                // criminal_record: Yup.string().required( "criminal record for Applicant One" ),
                // occupation: Yup.string().required("occupation of Applicant One"),
                // employer: Yup.string().required("employer name for Applicant one"),
                banking_bank: Yup.string().nullable().required("name of the bank for Applicant One"),
                banking_account_type: Yup.string().nullable().required("type of account for Applicant One"),
                // banking_branch: Yup.string().nullable().required("bank branch for Applicant One"),
                banking_account_number: Yup.string().nullable().required("bank acount number for Applicant One"),
                banking_account_holder: Yup.string().nullable().required("banking account holder for Applicant One"),
                gross_salary: Yup.string().required("gross salary of Applicant One"),

                joint_application_title: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().required("title for Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_name: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().required("name for Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_surname: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().required("surname for Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_email: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().required("email for Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_cell: Yup.mixed().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.mixed()
                        .required("cell number for Applicant Two")
                        .test('is-valid-phone', 'Please enter a valid phone number', function(value) {
                            // Skip validation if it's an empty string or undefined and joint application isn't active
                            if (!value || value === '+') return false;
                            // Otherwise, proceed with validation
                            return true;
                        }),
                    otherwise: Yup.mixed().nullable(), // Don't validate when joint application isn't active
                }),
                joint_application_id_number: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().required("id or passport number for Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_nationality: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().required("nationality of Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_date_of_birth: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().required("date of birth of Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_occupation: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().required("occupation of Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_employer: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().required("employer for Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_banking_bank: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().nullable().required("name of the bank for Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_banking_account_type: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().nullable().required("type of account for Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_banking_account_number: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().nullable().required("bank account number for Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                joint_application_banking_account_holder: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().nullable().required("banking account holder for Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                // joint_application_current_address: Yup.string().when("joint_application", {
                //     is: (value) => value && value == 1,
                //     then: Yup.string().required("current address for Applicant Two"),
                //     otherwise: Yup.string().nullable(),
                // }),
                // joint_application_current_suburb: Yup.string().when("joint_application", {
                //     is: (value) => value && value == 1,
                //     then: Yup.string().required("current suburb for Applicant Two"),
                //     otherwise: Yup.string().nullable(),
                // }),
                // joint_application_current_city: Yup.string().when("joint_application", {
                //     is: (value) => value && value == 1,
                //     then: Yup.string().required("current city for Applicant Two"),
                //     otherwise: Yup.string().nullable(),
                // }),
                // joint_application_current_postal: Yup.string().when("joint_application", {
                //     is: (value) => value && value == 1,
                //     then: Yup.string().required("current postal code for Applicant Two"),
                //     otherwise: Yup.string().nullable(),
                // }),
                // joint_application_current_province: Yup.string().when("joint_application", {
                //     is: (value) => value && value == 1,
                //     then: Yup.string().required("current province for Applicant Two"),
                //     otherwise: Yup.string().nullable(),
                // }),
                joint_application_gross_salary: Yup.string().when("joint_application", {
                    is: (value) => value && value == 1,
                    then: Yup.string().required("gross salary of Applicant Two"),
                    otherwise: Yup.string().nullable(),
                }),
                // joint_application_net_income: Yup.string().when("joint_application", {
                //     is: (value) => value && value == 1,
                //     then: Yup.string().required("net income of Applicant Two"),
                //     otherwise: Yup.string().nullable(),
                // }),
                // joint_application_total_monthly: Yup.string().when("joint_application", {
                //     is: (value) => value && value == 1,
                //     then: Yup.string().required("total monthly expenses of Applicant Two"),
                //     otherwise: Yup.string().nullable(),
                // }),
                // popi: Yup.number().positive("confirmation to the POPI Act").min(1, "confirmation to the POPI Act"),
                // legal: Yup.number().positive("confirmation that your information is legally correct").min(1, "confirmation that your information is legally correct"),
                // tpn: Yup.number().positive("confirmation that a Credit Check will be run").min(1, "confirmation that a Credit Check will be run"),
                // admin: Yup.number().positive("confirmation that you will pay the non-refundable admin fee").min(1, "confirmation that you will pay the non-refundable admin fee"),
                terms: Yup.boolean().oneOf([true], "You must accept all Terms and Conditions"),
                // company application
            })}
            onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                if (values.terms != true) {
                    alert("Please accept all Terms and Conditions");
                    return;
                }

                setSubmitting(true);

                //format cell
                values.cell = values.cell && values.cell.replace("+", "");
                values.home_number = values.home_number && values.home_number.replace("+", "");
                values.employer_contact = values.employer_contact && values.employer_contact.replace("+", "");
                values.joint_application_cell = values.joint_application_cell && values.joint_application_cell.replace("+", "");
                values.kin_contact = values.kin_contact && values.kin_contact.replace("+", "");

                // adjust the financial information to floats
                // deep clone object to not adjust original values
                let data = JSON.parse(JSON.stringify(values));
                data.available_income = availableIncome(data.net_income, data.total_monthly);

                data.gross_salary = data.gross_salary ? formatToFloat(data.gross_salary) : null;

                data.other_income = data.other_income ? formatToFloat(data.other_income) : null;
                data.net_income = data.net_income ? formatToFloat(data.net_income) : null;
                data.rent = data.rent ? formatToFloat(data.rent) : null;
                data.car_repayments = data.car_repayments ? formatToFloat(data.car_repayments) : null;
                data.loan_repayments = data.loan_repayments ? formatToFloat(data.loan_repayments) : null;
                data.card_repayments = data.card_repayments ? formatToFloat(data.card_repayments) : null;
                data.groceries_costs = data.groceries_costs ? formatToFloat(data.groceries_costs) : null;
                data.fuel_costs = data.fuel_costs ? formatToFloat(data.fuel_costs) : null;
                data.school_fees = data.school_fees ? formatToFloat(data.school_fees) : null;
                data.medical_aid = data.medical_aid ? formatToFloat(data.medical_aid) : null;
                data.total_monthly = data.total_monthly ? formatToFloat(data.total_monthly) : null;

                // Find and set bank code for primary applicant
                const primaryBank = bankList.find(bank => bank.value === data.banking_bank || bank.label === data.banking_bank);
                if (primaryBank) {
                    data.banking_bank_code = primaryBank.code;
                    // Remove branch code field since we'll use bank code from Paystack
                    data.banking_branch_code = primaryBank.code;
                    console.log("Set primary applicant bank code:", primaryBank.code);
                }

                if (data.joint_application === 1) {
                    data.joint_application_available_income = availableIncome(data.joint_application_net_income, data.joint_application_total_monthly);

                    data.joint_application_gross_salary = data.joint_application_gross_salary ? formatToFloat(data.joint_application_gross_salary) : null;
                    data.joint_application_other_income = data.joint_application_other_income ? formatToFloat(data.joint_application_other_income) : null;
                    data.joint_application_net_income = data.joint_application_net_income ? formatToFloat(data.joint_application_net_income) : null;
                    data.joint_application_rent = data.joint_application_rent ? formatToFloat(data.joint_application_rent) : null;
                    data.joint_application_car_repayments = data.joint_application_car_repayments ? formatToFloat(data.joint_application_car_repayments) : null;
                    data.joint_application_loan_repayments = data.joint_application_loan_repayments ? formatToFloat(data.joint_application_loan_repayments) : null;
                    data.joint_application_card_repayments = data.joint_application_card_repayments ? formatToFloat(data.joint_application_card_repayments) : null;
                    data.joint_application_groceries_costs = data.joint_application_groceries_costs ? formatToFloat(data.joint_application_groceries_costs) : null;
                    data.joint_application_fuel_costs = data.joint_application_fuel_costs ? formatToFloat(data.joint_application_fuel_costs) : null;
                    data.joint_application_school_fees = data.joint_application_school_fees ? formatToFloat(data.joint_application_school_fees) : null;
                    data.joint_application_medical_aid = data.joint_application_medical_aid ? formatToFloat(data.joint_application_medical_aid) : null;
                    data.joint_application_total_monthly = data.joint_application_total_monthly ? formatToFloat(data.joint_application_total_monthly) : null;

                    // Find and set bank code for joint applicant
                    const jointBank = bankList.find(bank => bank.value === data.joint_application_banking_bank || bank.label === data.joint_application_banking_bank);
                    if (jointBank) {
                        data.joint_application_banking_bank_code = jointBank.code;
                        // Remove branch code field since we'll use bank code from Paystack
                        data.joint_application_banking_branch_code = jointBank.code;
                        console.log("Set joint applicant bank code:", jointBank.code);
                    }
                }

                let api_method = "post";
                if (url) {
                    api_method = "put";
                    data.url = url;

                    data.lease_surance = current_booking.rates[current_booking.rates.length - 1].lease_surance;
                }

                await axios({
                    method: api_method,
                    url: `${process.env.REACT_APP_API_URL}user`,
                    data: data,
                })
                    .then((response) => {
                        if (url) {
                            toast(
                                <NotificationToast
                                    title={"Application Updated"}
                                    message="Your application has succesfully been updated. Keep an eye out on your email and cellphone for future communication"
                                />
                            );
                        } else {
                            toast(
                                <NotificationToast
                                    title={"Application Successful. Redirecting to Edit form"}
                                    message="Your application has succesfully been created. Keep an eye out on your email and cellphone for future communication"
                                />
                            );
                            setTimeout(() => {
                                window.location = window.location.origin + "/edit_application/" + response.data;
                            }, 3000);
                        }

                        // Add the + back for form prettiness
                        values.cell = "+" + values.cell;
                        values.home_number = "+" + values.home_number;
                        values.employer_contact = "+" + values.employer_contact;
                        values.joint_application_cell = "+" + values.joint_application_cell;
                        values.kin_contact = "+" + values.kin_contact;
                    })
                    .catch((response) => {
                        toast(<NotificationToast title={"Something went wrong"} message={response.response.data.message} type="error" />);
                    });
            }}
        >
            {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                <Form onSubmit={handleSubmit}>
                    <div className="pb-32 elative bg-fixed bg-bottom bg-cover bg-no-repeat application_form">
                        <header className="max-w-7xl mx-auto py-10 px-2 lg:px-0">
                            <div className="lg:flex">
                                <img className="h-24" src="/images/logo.svg" alt="Quorum Living" />
                                <div className="py-6 lg:py-0 mx-auto md:w-96 text-center">
                                    <h1 className="text-3xl text-white">
                                        Welcome {url && "back"} to QLiving {url && current_booking && current_booking.user && current_booking.user.name + " " + current_booking.user.surname}
                                    </h1>
                                    {url ? (
                                        <p className="text-gray-200 mt-4">
                                            Update your information at each <span className="italic font-bold">Section</span> and update your application by clicking{" "}
                                            <span className="italic font-bold">Submit Application</span>.
                                        </p>
                                    ) : (
                                        <p className="text-gray-200 mt-4">
                                            Complete the form below by filling in all the required information at each <span className="italic font-bold">Section</span> and start your application by
                                            clicking <span className="italic font-bold">Submit Application</span>.
                                        </p>
                                    )}
                                    {Object.keys(errors).length > 0 && (
                                        <>
                                            <p className="mt-6 text-red-500 text-lg font-bold">Cannot apply!</p>{" "}
                                            <p className="my-2 text-red-500 text-sm">Please make sure you fill in all sections of the form below:</p>
                                        </>
                                    )}
                                    {Object.keys(errors).length < 1 && (
                                        <div className="px-8">
                                            <SubmitButton
                                                name="Save"
                                                disabled={!isValid || isSubmitting || !dirty}
                                                isSubmitting={isSubmitting}
                                                text={url ? "Update Application" : "Submit Application"}
                                                style={{
                                                    width: "100%",
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </header>
                    </div>
                    <main className="-mt-32">
                        <div className="max-w-7xl mx-auto pb-2 px-4">
                            <div className="space-y-8 divide-y divide-gray-200">
                                <div className="w-full">
                                    <div className="xl:grid xl:grid-cols-12">
                                        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                                            {/* <Tab.List className="pb-6 lg:pb-24 bg-light_background xl:col-span-2 rounded-3xl lg:rounded-l-3xl w-full xl:w-72 border-0">
                                                <div className="h-14 flex items-center rounded-t-3xl xl:rounded-tl-3xl xl:rounded-tr-none justify-center bg-dark_background">
                                                    <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Sections</h1>
                                                </div>
                                                {subNavigation.map((item) => (
                                                    <Tab
                                                        key={item.name}
                                                        className={({ selected }) =>
                                                            classNames(
                                                                "group w-full text-sm flex items-center border-l-8 p-4 hover:cursor-pointer hover:bg-gray-200 hover:border-l-primary_hover focus:outline-none ",
                                                                selected ? "border-l-primary bg-gray-100 text-primary" : "bg-light_background border-l-light_background"
                                                            )
                                                        }
                                                    >
                                                        <span className="truncate">
                                                            <FontAwesomeIcon icon={item.icon} aria-hidden="true" className="mr-4" />
                                                            {item.name}
                                                        </span>
                                                    </Tab>
                                                ))}
                                                {!values.company_application && (
                                                    <Tab
                                                        className={({ selected }) =>
                                                            classNames(
                                                                "group w-full text-sm flex items-center border-l-8 p-4 hover:cursor-pointer hover:bg-gray-200 hover:border-l-primary_hover focus:outline-none ",
                                                                selected ? "border-l-primary bg-gray-100 text-primary" : "bg-light_background border-l-light_background"
                                                            )
                                                        }
                                                    >
                                                        <span className="truncate">
                                                            <FontAwesomeIcon icon={faHouse} aria-hidden="true" className="mr-4" />- Current Premises
                                                        </span>
                                                    </Tab>
                                                )}
                                                {values.joint_application == 1 &&
                                                    jointSubNavigation.map((item) => (
                                                        <Tab
                                                            key={item.name}
                                                            className={({ selected }) =>
                                                                classNames(
                                                                    "group w-full text-sm flex items-center border-l-8 p-4 hover:cursor-pointer hover:bg-gray-200 hover:border-l-primary_hover focus:outline-none ",
                                                                    selected ? "border-l-primary bg-gray-100 text-primary" : "bg-light_background border-l-light_background"
                                                                )
                                                            }
                                                        >
                                                            <span className="truncate">
                                                                <FontAwesomeIcon icon={item.icon} aria-hidden="true" className="mr-4" />
                                                                {item.name}
                                                            </span>
                                                        </Tab>
                                                    ))}
                                            </Tab.List> */}
                                            <Tab.Panels className="xl:ml-20 my-6 xl:my-0 rounded-3xl xl:rounded-3xl bg-gray-100 xl:col-span-11 w-full">
                                                {/* <Tab.Panel className="xl:col-span-9 pb-12"> */}
                                                <div className="h-14 flex items-center rounded-t-3xl justify-center bg-dark_background">
                                                    <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Your Booking</h1>
                                                </div>

                                                {/* {url && <QLDocumentUploadComp url={url} />} */}

                                                <div className="py-8 px-4 sm:px-12 space-y-6  xl:px-6">
                                                    <Field name="joint_application" as={SettingsSwitch} label="Is this a Joint Application" />
                                                    <Field name="company_application" as={SettingsSwitch} label="Is this a Company Application" />
                                                </div>
                                                <div className="px-4 sm:px-12 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6">
                                                    <div className="col-span-3">
                                                        <SettingsSelect
                                                            label="What is your desired Building?"
                                                            name="property_id"
                                                            required
                                                            getUnitTypes={getUnitTypes}
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            value={values.property_id}
                                                            touched={touched.property_id}
                                                            error={errors.property_id}
                                                            options={properties}
                                                        />
                                                        {errors.property_id && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                We require the <span className="font-bold italic">{errors.property_id}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <SettingsSelect
                                                            label="What type of unit would you like?"
                                                            required
                                                            name="unit_type"
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            value={values.unit_type}
                                                            touched={touched.unit_type}
                                                            error={errors.unit_type}
                                                            options={unitTypes.length > 0 ? unitTypes : "none"}
                                                        />
                                                        {errors.unit_type && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                We require the <span className="font-bold italic">{errors.unit_type}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {/* <Field name="viewed_unit" as={ SettingsSwitch } label="Have you viewed the Unit?"
                                                            setFieldValue={ setFieldValue } /> */}
                                                    <Field name="parking" as={SettingsSwitch} label="Do you require parking?" />

                                                    <Field
                                                        name="occupants"
                                                        as={SettingsCounter}
                                                        value={values.occupants}
                                                        label="What is the total number of occupants?"
                                                        setFieldValue={setFieldValue}
                                                    />
                                                    {values.occupants > 0 && <Field name="occupants_over_10" as={SettingsSwitch} label="Are any of the other occupants under the age of 10?" />}
                                                    {/* <SettingsSelect
                                                            label="Assisted By"
                                                            name="assisted_by"
                                                            onChange={ setFieldValue }
                                                            onBlur={ setFieldTouched }
                                                            value={ values.assisted_by }
                                                            touched={ touched.assisted_by }
                                                            error={ errors.assisted_by }
                                                            options="assisted_by"
                                                        /> */}
                                                    <SettingsSelect
                                                        label="Where did you hear about us?"
                                                        name="referral"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.referral}
                                                        touched={touched.referral}
                                                        error={errors.referral}
                                                        options="referral"
                                                    />
                                                </div>
                                                <div className="py-8 px-4 sm:px-12 space-y-6  xl:px-6">
                                                    {/* {url ? "" :
                                                            <>
                                                                <Field
                                                                    name="lease_surance"
                                                                    as={SettingsSwitch}
                                                                    label="Standard Cash Deposit OR Deposit Waiver Fee"
                                                                    description="Q Living now offers a Deposit Waiver fee. Instead of paying an upfront deposit, you can choose to rather pay a monthly deposit waiver fee equal to 5% of your monthly rental. This frees up some extra cash for you when moving in!  At the end of your lease term, and should you not wish to renew, you may be able to simply move out. All that is required of you is to comply with the terms of your lease agreement throughout your stay. No hidden charges. No catch. Cha-Ching 💸.

                                                                    Toggle the option to yes (✓) to opt for the deposit waiver fee"
                                                                />
                                                            </>
                                                        } */}
                                                </div>
                                                {!(errors.property_id || errors.unit_type) ? (
                                                    <div className="flex justify-around">
                                                        <CustomButton text={"Continue"} onClick={() => myRefAp1.current.scrollIntoView()} />
                                                    </div>
                                                ) : (
                                                    // <div className="text-orange-500 col-span-2 text-sm font-light">
                                                    //     <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                    //     <span className="font-bold italic">Please fill in all the required information before moving to the next step.</span>
                                                    // </div>
                                                    ""
                                                )}
                                                {/* </Tab.Panel> */}

                                                {/* <Tab.Panel className="xl:col-span-9 pb-12"> */}
                                                <div className="h-14 flex items-center justify-center bg-dark_background mt-5" ref={myRefAp1}>
                                                    <a href={"#"}></a>
                                                    <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Applicant One</h1>
                                                </div>
                                                <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6">
                                                    <SettingsSelect
                                                        label="Will this Applicant occupy the premises?                                                        "
                                                        name="occupy_unit"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.occupy_unit}
                                                        touched={touched.occupy_unit}
                                                        error={errors.occupy_unit}
                                                        options="yesNo"
                                                    />
                                                    <div className="col-span-3">
                                                        <SettingsSelect
                                                            label="Title"
                                                            name="title"
                                                            required
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            value={values.title}
                                                            touched={touched.title}
                                                            error={errors.title}
                                                            options="titles"
                                                        />
                                                        {errors.title && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                We require the <span className="font-bold italic">{errors.title}</span>
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="col-span-3">
                                                        <Field name="name" type="text" as={SettingsTextInput} label="Name" required />
                                                        {errors.name && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                We require the <span className="font-bold italic">{errors.name}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="surname" type="text" as={SettingsTextInput} label="Surname" required />
                                                        {errors.surname && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                We require the <span className="font-bold italic">{errors.surname}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="email" type="email" as={SettingsTextInput} label="Email" required />
                                                        {errors.email && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                We require the <span className="font-bold italic">{errors.email}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {/* <Field name="cell" type="text" as={SettingsTextInput} prepend="+" label="Cell" required /> */}
                                                    <div className="col-span-3">
                                                        <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                            <label htmlFor="cell" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                <span>Cell</span>
                                                                {errors.cell && <p className="text-xs font-light text-red-400">required</p>}
                                                            </label>
                                                            <div className="mt-1">
                                                                <PhoneInput
                                                                    defaultCountry="ZA"
                                                                    international
                                                                    countryCallingCodeEditable={false}
                                                                    onChange={(val) => {
                                                                        setFieldValue("cell", val);
                                                                    }}
                                                                    onBlur={setFieldTouched}
                                                                    name="cell"
                                                                    value={values.cell}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        {errors.cell && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                We require the <span className="font-bold italic">{errors.cell}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                        <label htmlFor="home_number" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                            <span>Home Number</span>
                                                        </label>
                                                        <div className="mt-1">
                                                            <PhoneInput
                                                                defaultCountry="ZA"
                                                                international
                                                                countryCallingCodeEditable={false}
                                                                onChange={(val) => {
                                                                    setFieldValue("home_number", val);
                                                                }}
                                                                onBlur={setFieldTouched}
                                                                name="home_number"
                                                                value={values.home_number}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <Field name="home_number" type="text" as={SettingsTextInput} label="Home Number" prepend="+" /> */}
                                                    <div className="col-span-3">
                                                        <SettingsSelect
                                                            label="Nationality"
                                                            name="nationality"
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            value={values.nationality}
                                                            touched={touched.nationality}
                                                            error={errors.nationality}
                                                            options="nationality"
                                                            required
                                                        />
                                                        {errors.nationality && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                We require the <span className="font-bold italic">{errors.nationality}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="id_number" type="text" as={SettingsTextInput} label="ID or Passport Number" required nationality={values.nationality} />
                                                        {errors.id_number && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                We require the <span className="font-bold italic">{errors.id_number}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field
                                                            name="date_of_birth"
                                                            type="text"
                                                            as={SettingsDatePicker}
                                                            label="Date of Birth"
                                                            id_number={values.id_number}
                                                            nationality={values.nationality}
                                                            required
                                                        />
                                                        {errors.date_of_birth && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                We require the <span className="font-bold italic">{errors.date_of_birth}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {/*<div className="col-span-3">*/}
                                                    {/*    <Field name="car" type="text" as={SettingsTextInput} label="Car Model and Registration" />*/}
                                                    {/*    {errors.car && (*/}
                                                    {/*        <div className="text-orange-500 col-span-2 text-sm font-light">*/}
                                                    {/*            <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />*/}
                                                    {/*            We require the <span className="font-bold italic">{errors.car}</span>*/}
                                                    {/*        </div>*/}
                                                    {/*    )}*/}
                                                    {/*</div>*/}
                                                    {/*<SettingsSelect*/}
                                                    {/*    label="Criminal Record"*/}
                                                    {/*    name="criminal_record"*/}
                                                    {/*    onChange={setFieldValue}*/}
                                                    {/*    onBlur={setFieldTouched}*/}
                                                    {/*    value={values.criminal_record}*/}
                                                    {/*    touched={touched.criminal_record}*/}
                                                    {/*    error={errors.criminal_record}*/}
                                                    {/*    options="yesNo"*/}
                                                    {/*/>*/}
                                                    {/*<Field name="other" field_type="textarea" as={SettingsTextInput} label="Other" />*/}
                                                </div>
                                                <div className="flex justify-around">
                                                    {/* <CustomButton text={"Previous step"} onClick={() => setSelectedIndex(selectedIndex - 1)} /> */}
                                                    {!(
                                                        errors.title ||
                                                        errors.name ||
                                                        errors.surname ||
                                                        errors.email ||
                                                        errors.cell ||
                                                        errors.nationality ||
                                                        errors.id_number ||
                                                        errors.date_of_birth
                                                    ) ? (
                                                        <div className="flex justify-around">
                                                            <CustomButton text={"Continue"} onClick={() => myRefAp1Bnk.current.scrollIntoView()} />
                                                        </div>
                                                    ) : (
                                                        // <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        //     <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                        //     <span className="font-bold italic">Please fill in all the required information before moving to the next step.</span>
                                                        // </div>
                                                        ""
                                                    )}
                                                </div>
                                                {/* </Tab.Panel>

                                                <Tab.Panel className="xl:col-span-9 pb-12"> */}
                                                {showEmploymentInfo === true && (
                                                    <div className="h-14 flex items-center mt-5 justify-center bg-dark_background" ref={myRefAp1Emp}>
                                                        <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Applicant One - Employment Information</h1>
                                                    </div>
                                                )}

                                                {showEmploymentInfo === true && (
                                                    <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6">
                                                        {" "}
                                                        <div className="col-span-3">
                                                            <Field name="occupation" type="text" as={SettingsTextInput} label="Occupation" required />
                                                            {errors.occupation && (
                                                                <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                    <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                    We require the <span className="font-bold italic">{errors.occupation}</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {/* self_employed */}
                                                        <Field name="self_employed" as={SettingsSwitch} label="Are you self-employed?" />
                                                        <div className="col-span-3">
                                                            <Field name="employer" type="text" as={SettingsTextInput} label="Employer Name" required />
                                                            {errors.employer && (
                                                                <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                    <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                    We require the <span className="font-bold italic">{errors.employer}</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <Field name="employer_address" type="text" as={SettingsTextInput} label="Employer Address" />
                                                        {values.company_application && (
                                                            <>
                                                                <div className="col-span-3">
                                                                    <Field name="employer_suburb" type="text" as={SettingsTextInput} label="Employer Suburb" required />
                                                                    {errors.employer_suburb && (
                                                                        <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                            <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                            We require the <span className="font-bold italic">{errors.employer_suburb}</span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="col-span-3">
                                                                    <Field name="employer_city" type="text" as={SettingsTextInput} label="Employer City" required />
                                                                    {errors.employer_city && (
                                                                        <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                            <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                            We require the <span className="font-bold italic">{errors.employer_city}</span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="col-span-3">
                                                                    <Field name="employer_postal" type="text" as={SettingsTextInput} label="Employer Postal Code" required />
                                                                    {errors.employer_postal && (
                                                                        <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                            <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                            We require the <span className="font-bold italic">{errors.employer_postal}</span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="col-span-3">
                                                                    <SettingsSelect
                                                                        label="Employer Province"
                                                                        name="employer_province"
                                                                        onChange={setFieldValue}
                                                                        onBlur={setFieldTouched}
                                                                        value={values.employer_province}
                                                                        touched={touched.employer_province}
                                                                        error={errors.employer_province}
                                                                        options="provinces"
                                                                        required
                                                                    />
                                                                    {errors.employer_province && (
                                                                        <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                            <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                            We require the <span className="font-bold italic">{errors.employer_province}</span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="col-span-3">
                                                                    <Field
                                                                        name="employer_registration"
                                                                        as={SettingsTextInput}
                                                                        label="Employer Registration Number"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        business_registration={true}
                                                                        required
                                                                    />
                                                                    {errors.employer_registration && (
                                                                        <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                            <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                            We require the <span className="font-bold italic">{errors.employer_registration}</span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="col-span-3">
                                                                    <Field name="employer_it" type="text" as={SettingsTextInput} label="Employer IT Number" required />
                                                                    {errors.employer_it && (
                                                                        <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                            <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                            We require the <span className="font-bold italic">{errors.employer_it}</span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </>
                                                        )}
                                                        {/* <Field name="employer_contact" type="text" as={SettingsTextInput} label="Employer Contact Number" prepend="+" /> */}
                                                        <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                            <label htmlFor="employer_contact" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                <span>Employer Contact Number</span>
                                                            </label>
                                                            <div className="mt-1">
                                                                <PhoneInput
                                                                    defaultCountry="ZA"
                                                                    international
                                                                    countryCallingCodeEditable={false}
                                                                    onChange={(val) => {
                                                                        setFieldValue("employer_contact", val);
                                                                    }}
                                                                    onBlur={setFieldTouched}
                                                                    name="employer_contact"
                                                                    value={values.employer_contact}
                                                                />
                                                            </div>

                                                            {errors.employer_contact && (
                                                                <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                    <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                    We require the <span className="font-bold italic">{errors.employer_contact}</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}

                                                {showEmploymentInfo === true && (
                                                    <div className="flex justify-around">
                                                        {/* <CustomButton text={"Previous step"} onClick={() => setSelectedIndex(selectedIndex - 1)} /> */}
                                                        {!(
                                                            errors.employer ||
                                                            errors.employer_suburb ||
                                                            errors.employer_city ||
                                                            errors.employer_postal ||
                                                            errors.employer_province ||
                                                            errors.employer_registration ||
                                                            errors.employer_it ||
                                                            errors.occupation ||
                                                            errors.employer_contact
                                                        ) ? (
                                                            <div className="flex justify-around">
                                                                <CustomButton text={"Continue"} onClick={() => myRefAp1Bnk.current.scrollIntoView()} />
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                )}

                                                {/* </Tab.Panel> */}
                                                {/* <Tab.Panel className="xl:col-span-9 pb-12"> */}
                                                {showAffordability === true && (
                                                    <>
                                                        <div className="h-14 flex items-center mt-5 justify-center bg-dark_background" ref={myRefAp1Aff}>
                                                            <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Applicant One - Affordability</h1>
                                                        </div>

                                                        <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6">
                                                            <div className="col-span-3">
                                                                <Field
                                                                    name="gross_salary"
                                                                    as={SettingsTextInput}
                                                                    label="Gross Salary"
                                                                    prepend="R"
                                                                    currency="true"
                                                                    required
                                                                    small="(Before Deductions & Tax)"
                                                                />
                                                                {errors.gross_salary && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                        We require the <span className="font-bold italic">{errors.gross_salary}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {/* <Field name="other_income" as={SettingsTextInput} label="Other Income" prepend="R" currency="true" /> */}
                                                            <div className="col-span-3">
                                                                <Field
                                                                    name="net_income"
                                                                    as={SettingsTextInput}
                                                                    label="Net Income (A)"
                                                                    prepend="R"
                                                                    currency="true"
                                                                    required
                                                                    small="(Gross + Other = Net Income)"
                                                                />
                                                                {errors.net_income && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                        We require the <span className="font-bold italic">{errors.net_income}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {/* <Field name="rent" as={SettingsTextInput} label="Rent" prepend="R" currency="true" /> */}
                                                            {/* <Field name="car_repayments" as={SettingsTextInput} label="Car Repayments" prepend="R" currency="true" /> */}
                                                            {/* <Field name="loan_repayments" as={SettingsTextInput} label="Loan Repayments" prepend="R" currency="true" /> */}
                                                            {/* <Field name="card_repayments" as={SettingsTextInput} label="Card Repayments" prepend="R" currency="true" /> */}
                                                            {/* <div className="col-span-3">
                                                        <Field name="groceries_costs" as={SettingsTextInput} label="Groceries Costs" prepend="R" currency="true" required />
                                                        {errors.groceries_costs && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                We require the <span className="font-bold italic">{errors.groceries_costs}</span>
                                                            </div>
                                                        )}
                                                    </div> */}
                                                            {/* <Field name="fuel_costs" as={SettingsTextInput} label="Fuel Costs" prepend="R" currency="true" /> */}
                                                            {/* <Field name="school_fees" as={SettingsTextInput} label="School Fees" prepend="R" currency="true" /> */}
                                                            {/* <Field name="medical_aid" as={SettingsTextInput} label="Medical Aid" prepend="R" currency="true" /> */}
                                                            <div className="col-span-3">
                                                                <Field name="total_monthly" as={SettingsTextInput} label="Total Monthly Expenses (B)" prepend="R" currency="true" required />
                                                                {errors.total_monthly && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                        We require the <span className="font-bold italic">{errors.total_monthly}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <Field
                                                                name="available_income"
                                                                as={SettingsTextInput}
                                                                label="Available Income (A-B)"
                                                                disabled
                                                                value={availableIncome(values.net_income, values.total_monthly)}
                                                                prepend="R"
                                                                currency="true"
                                                            />
                                                            <Field name="other" field_type="textarea" as={SettingsTextInput} label="Other" />
                                                        </div>
                                                        <div className="flex justify-around">
                                                            {/* <CustomButton text={"Previous step"} onClick={() => setSelectedIndex(selectedIndex - 1)} /> */}
                                                            {errors.gross_salary || errors.net_income || errors.total_monthly ? (
                                                                // <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                //     <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                //     <span className="font-bold italic">Please fill in all the required information before moving to the next step.</span>
                                                                // </div>
                                                                ""
                                                            ) : !values.company_application ? (
                                                                <CustomButton text={"Continue"} onClick={() => myRefAp1Bnk.current.scrollIntoView()} />
                                                            ) : values.joint_application ? (
                                                                <CustomButton text={"Continue"} onClick={() => myRefAp2.current.scrollIntoView()} />
                                                            ) : (
                                                                // Object.keys(errors).length < 1 && (
                                                                // <SubmitButton
                                                                //     name="Save"
                                                                //     disabled={!isValid || isSubmitting || !dirty}
                                                                //     isSubmitting={isSubmitting}
                                                                //     text={url ? "Update Application" : "Submit Application"}
                                                                // />
                                                                // )
                                                                <CustomButton text={"Continue"} onClick={() => myRefAp1Bnk.current.scrollIntoView()} />
                                                            )}
                                                        </div>
                                                    </>
                                                )}


                                                {/* <Tab.Panel className="xl:col-span-9 pb-12"> */}
                                                <div className="h-14 flex items-center mt-5 justify-center bg-dark_background" ref={myRefAp1Bnk}>
                                                    <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Applicant One - Banking Details</h1>
                                                </div>

                                                {values.company_application == 1 && (
                                                    <h2 className="pt-6 px-4 text-center leading-6 font-light text-dark_gray capitalize">Please enter the banking details of your company</h2>
                                                )}

                                                <p className="text-center mt-8 text-red-500">In order to ensure you are able to afford your rental, we need your details to run a credit check.<br />Kindly note all information is secured and kept private, see our terms at the bottom of this application.</p>

                                                <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6">

                                                    <div className="col-span-3">
                                                        {/* <Field name="banking_bank" type="text" as={SettingsTextInput} label="Bank" required /> */}
                                                        <SettingsSelect
                                                            label="Bank"
                                                            name="banking_bank"
                                                            onBlur={setFieldTouched}
                                                            value={values.banking_bank}
                                                            touched={touched.banking_bank}
                                                            error={errors.banking_bank}
                                                            options={bankList}
                                                        />
                                                        {errors.banking_bank && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                We require the <span className="font-bold italic">{errors.banking_bank}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <SettingsSelect
                                                            label="Account Type"
                                                            name="banking_account_type"
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            value={values.banking_account_type}
                                                            touched={touched.banking_account_type}
                                                            error={errors.banking_account_type}
                                                            options="bank_account_types"
                                                        />
                                                        {errors.banking_account_type && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                We require the <span className="font-bold italic">{errors.banking_account_type}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="banking_account_number" type="text" as={SettingsTextInput} label="Account Number" required />
                                                        {errors.banking_account_number && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                We require the <span className="font-bold italic">{errors.banking_account_number}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="banking_account_holder" type="text" as={SettingsTextInput} label="Account Holder" required />
                                                        {errors.banking_account_holder && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                We require the <span className="font-bold italic">{errors.banking_account_holder}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field
                                                            name="gross_salary"
                                                            as={SettingsTextInput}
                                                            label="Gross Salary"
                                                            prepend="R"
                                                            currency="true"
                                                            required
                                                            small="(Before Deductions & Tax)"
                                                        />
                                                        {errors.gross_salary && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                We require the <span className="font-bold italic">{errors.gross_salary}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="flex justify-around">
                                                    {/* <CustomButton text={"Previous step"} onClick={() => setSelectedIndex(selectedIndex - 1)} /> */}
                                                    {!(
                                                        errors.banking_bank ||
                                                        errors.banking_account_type ||
                                                        errors.banking_account_number ||
                                                        errors.banking_account_holder
                                                    ) ? (
                                                        <div className="flex justify-around">
                                                            <CustomButton text={"Continue"} onClick={() => myRefTerms.current.scrollIntoView()} />
                                                        </div>
                                                    ) : (
                                                        // <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        //     <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                        //     <span className="font-bold italic">Please fill in all the required information before moving to the next step.</span>
                                                        // </div>
                                                        ""
                                                    )}
                                                </div>
                                                {/* </Tab.Panel> */}

                                                {/* <Tab.Panel className="xl:col-span-9 pb-12"> */}
                                                {showNOK === true && (
                                                    <>
                                                        <div className="h-14 flex items-center mt-5 justify-center bg-dark_background" ref={myRefAp1NOK}>
                                                            <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Applicant One - Next of Kin</h1>
                                                        </div>

                                                        <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6">
                                                            <SettingsSelect
                                                                label="Title"
                                                                name="kin_title"
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                value={values.kin_title}
                                                                touched={touched.kin_title}
                                                                error={errors.kin_title}
                                                                options="titles"
                                                            />
                                                            <Field name="kin_name" type="text" as={SettingsTextInput} label="Name" />
                                                            <Field name="kin_surname" type="text" as={SettingsTextInput} label="Surname" />
                                                            <Field name="kin_relationship" type="text" as={SettingsTextInput} label="Relationship" />
                                                            {/* <Field name="kin_contact" type="text" as={SettingsTextInput} label="Contact" prepend="+" /> */}
                                                            <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                                <label htmlFor="kin_contact" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                    <span>Contact</span>
                                                                </label>
                                                                <div className="mt-1">
                                                                    <PhoneInput
                                                                        defaultCountry="ZA"
                                                                        international
                                                                        countryCallingCodeEditable={false}
                                                                        onChange={(val) => {
                                                                            setFieldValue("kin_contact", val);
                                                                        }}
                                                                        onBlur={setFieldTouched}
                                                                        name="kin_contact"
                                                                        value={values.kin_contact}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <Field name="kin_email" type="email" as={SettingsTextInput} label="Email" />
                                                            <Field name="kin_address" type="text" as={SettingsTextInput} label="Address" />
                                                        </div>
                                                        <div className="flex justify-around">
                                                            {/* <CustomButton text={"Previous step"} onClick={() => setSelectedIndex(selectedIndex - 1)} /> */}
                                                            <CustomButton text={"Continue"} onClick={() => myRefAp2.current.scrollIntoView()} />
                                                        </div>
                                                        {/* </Tab.Panel> */}
                                                    </>
                                                )}

                                                {values.joint_application == 1 && (
                                                    <>
                                                        <div className="h-14 flex items-center mt-5 justify-center bg-dark_background" ref={myRefAp2}>
                                                            <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Applicant Two</h1>
                                                        </div>

                                                        <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6">
                                                            <div className="col-span-3">
                                                                <SettingsSelect
                                                                    label="Title"
                                                                    name="joint_application_title"
                                                                    required
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.joint_application_title}
                                                                    touched={touched.joint_application_title}
                                                                    error={errors.joint_application_title}
                                                                    options="titles"
                                                                />
                                                                {errors.joint_application_title && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_title}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="joint_application_name" type="text" as={SettingsTextInput} label="Name" required />
                                                                {errors.joint_application_name && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_name}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="joint_application_surname" type="text" as={SettingsTextInput} label="Surname" required />
                                                                {errors.joint_application_surname && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_surname}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="joint_application_email" type="email" as={SettingsTextInput} label="Email" required />
                                                                {errors.joint_application_email && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_email}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                                    <label htmlFor="joint_application_cell" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                        <span>Cell</span>
                                                                        {errors.joint_application_cell && <p className="text-xs font-light text-red-400">required</p>}
                                                                    </label>
                                                                    <div className="mt-1">
                                                                        <PhoneInput
                                                                            defaultCountry="ZA"
                                                                            international
                                                                            countryCallingCodeEditable={false}
                                                                            onChange={(val) => {
                                                                                setFieldValue("joint_application_cell", val);
                                                                            }}
                                                                            onBlur={setFieldTouched}
                                                                            name="joint_application_cell"
                                                                            value={values.joint_application_cell}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {errors.joint_application_cell && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_cell}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <SettingsSelect
                                                                    label="Nationality"
                                                                    name="joint_application_nationality"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.joint_application_nationality}
                                                                    touched={touched.joint_application_nationality}
                                                                    error={errors.joint_application_nationality}
                                                                    options="nationality"
                                                                    required
                                                                />
                                                                {errors.joint_application_nationality && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_nationality}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="joint_application_id_number" type="text" as={SettingsTextInput} label="ID or Passport Number" required nationality={values.joint_application_nationality} />
                                                                {errors.joint_application_id_number && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_id_number}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field
                                                                    name="joint_application_date_of_birth"
                                                                    type="text"
                                                                    as={SettingsDatePicker}
                                                                    label="Date of Birth"
                                                                    id_number={values.joint_application_id_number}
                                                                    nationality={values.joint_application_nationality}
                                                                    required
                                                                />
                                                                {errors.joint_application_date_of_birth && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_date_of_birth}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="joint_application_occupation" type="text" as={SettingsTextInput} label="Occupation" required />
                                                                {errors.joint_application_occupation && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_occupation}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="joint_application_employer" type="text" as={SettingsTextInput} label="Employer Name" required />
                                                                {errors.joint_application_employer && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_employer}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        {/* <Tab.Panel className="xl:col-span-9 pb-12"> */}
                                                        <div className="h-14 flex items-center mt-5 justify-center bg-dark_background" ref={myRefAp2Bnk}>
                                                            <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Applicant Two - Banking Details</h1>
                                                        </div>

                                                        <p className="text-center mt-8 text-red-500">In order to ensure you are able to afford your rental, we need your details to run a credit check.<br />Kindly note all information is secured and kept private, see our terms at the bottom of this application.</p>

                                                        <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6">
                                                            <div className="col-span-3">
                                                                <SettingsSelect
                                                                    label="Bank"
                                                                    name="joint_application_banking_bank"
                                                                    onBlur={setFieldTouched}
                                                                    value={values.joint_application_banking_bank}
                                                                    touched={touched.joint_application_banking_bank}
                                                                    error={errors.joint_application_banking_bank}
                                                                    options={bankList}
                                                                />
                                                                {errors.joint_application_banking_bank && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_banking_bank}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <SettingsSelect
                                                                    label="Account Type"
                                                                    name="joint_application_banking_account_type"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.joint_application_banking_account_type}
                                                                    touched={touched.joint_application_banking_account_type}
                                                                    error={errors.joint_application_banking_account_type}
                                                                    options="bank_account_types"
                                                                />
                                                                {errors.joint_application_banking_account_type && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_banking_account_type}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="joint_application_banking_account_number" type="text" as={SettingsTextInput} label="Account Number" required />
                                                                {errors.joint_application_banking_account_number && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_banking_account_number}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="joint_application_banking_account_holder" type="text" as={SettingsTextInput} label="Account Holder" required />
                                                                {errors.joint_application_banking_account_holder && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_banking_account_holder}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field
                                                                    name="joint_application_gross_salary"
                                                                    as={SettingsTextInput}
                                                                    label="Gross Salary"
                                                                    prepend="R"
                                                                    currency="true"
                                                                    required
                                                                    small="(Before Deductions & Tax)"
                                                                />
                                                                {errors.joint_application_gross_salary && (
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                        We require the <span className="font-bold italic">{errors.joint_application_gross_salary}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-around">
                                                            {!(
                                                                errors.joint_application_banking_bank ||
                                                                errors.joint_application_banking_account_type ||
                                                                errors.joint_application_banking_account_number ||
                                                                errors.joint_application_banking_account_holder
                                                            ) ? (
                                                                <div className="flex justify-around">
                                                                    <CustomButton text={"Continue"} onClick={() => myRefTerms.current.scrollIntoView()} />
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        {/* </Tab.Panel> */}
                                                    </>
                                                )}

                                                <div className="h-14 flex items-center mt-5 justify-center bg-dark_background" ref={myRefTerms}>
                                                    <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Terms</h1>
                                                </div>

                                                <div className="py-8 px-4 sm:px-12 space-y-6  xl:px-6">
                                                    <p>
                                                        I agree to pay the non-refundable R 1000 Admin Fee
                                                        <br />
                                                        <span className="text-sm text-gray-400">Upon approval of my application or prior to occupation of the unit</span>
                                                    </p>

                                                    <p>
                                                        I confirm that the information provided is legally correct and binding in a court of law. The Applicant/s hereby warrant/s, represents and
                                                        undertakes that any information provided to QPG Holdings Proprietary Limited (the 'Company') is true and accurate and it has not knowingly
                                                        withheld information which, if disclosed, might affect the Company's decision in whether or not to agree to conclude a lease with the
                                                        Applicant/s.
                                                    </p>

                                                    <p>
                                                        I Agree to the POPI ACT
                                                        <br />
                                                        <span className="text-sm text-gray-400">
                                                            QPG Holdings Proprietary Limited and its subsidiaries, which includes Urban Circle, is compliant with the Protection of Personal Information
                                                            Act (POPIA), also known as the POPI Act, which is mandatory for all organisations in South Africa. POPI makes it illegal for us to collect,
                                                            use or store the personal information of customers and businesses unless it is done in accordance with the laws and regulations prescribed
                                                            in the POPI Act.
                                                        </span>
                                                    </p>

                                                    <p>
                                                        I Agree to the Credit Check
                                                        <br />
                                                        <span className="text-sm text-gray-400">
                                                            By ticking this box, I hereby agree and acknowledge that QPG Holdings Proprietary Limited may, via any one or more registered credit
                                                            bureaus, preform any credit search on my credit profile; monitor my payment profile; use any new information or data on any future credit
                                                            applications; and/or record whether the account is being conducted in a satisfactory manner
                                                        </span>
                                                    </p>

                                                    <Field name="terms" as={SettingsSwitch} required label="I Agree to all of the above Terms and Conditions" />

                                                    {errors.terms && (
                                                        <div className="text-orange-500 col-span-2 text-sm font-light">
                                                            <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                            We require <span className="font-bold italic">Acceptance of all Terms and Conditions</span>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="py-4">
                                                    {Object.keys(errors).length > 0 && (
                                                        <div className="bg-red-50 border border-red-200 rounded-md p-4 mb-4">
                                                            <div className="flex">
                                                                <div className="flex-shrink-0">
                                                                    <FontAwesomeIcon
                                                                        className="h-5 w-5 text-red-400"
                                                                        icon={faTriangleExclamation}
                                                                    />
                                                                </div>
                                                                <div className="ml-3">
                                                                    <h3 className="text-sm font-medium text-red-800">
                                                                        Please fix the following errors before submitting:
                                                                    </h3>
                                                                    <div className="mt-2 text-sm text-red-700">
                                                                        <ul className="list-disc pl-5 space-y-1">
                                                                            {Object.entries(errors).map(([field, error]) => (
                                                                                <li key={field}>
                                                                                    {error.toString().charAt(0).toUpperCase() +
                                                                                        error.toString().slice(1)}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="py-8 px-4 sm:px-12 space-y-6  xl:px-6">
                                                    <SubmitButton
                                                        name="Save"
                                                        disabled={!isValid || isSubmitting || !dirty || Object.keys(errors).length < 1}
                                                        isSubmitting={isSubmitting}
                                                        text={url ? "Update Application" : "Submit Application"}
                                                        style={{ width: "100%" }}
                                                    />
                                                </div>
                                            </Tab.Panels>
                                        </Tab.Group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </Form>
            )}
        </Formik>
    );
}
