import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import axios from "axios";
import CustomButton from "../Helpers/CustomButton";
import {openModal} from "../../store/modal/modalReducer";
import {faBed} from "@fortawesome/pro-regular-svg-icons";
import {useParams} from "react-router-dom";
import {getBooking} from "../../store/booking/bookingActions";

export default function QueuePlate({current_staff, current_booking, properties}) {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const {type} = useParams();
	const [queueData, setQueueData] = useState([]);
	const [selectedProperty, setSelectedProperty] = useState(current_booking?.property?.id || '');
	const [selectedGender, setSelectedGender] = useState('0');

	const fetchBedQueueInfo = async (propertyId = null) => {
		if (!propertyId) return;

		try {
			setLoading(true);
			const response = await axios.post(`${process.env.REACT_APP_API_URL}bed-queue`, {
				booking_id: current_booking?.id,
				property_id: propertyId,
			});
			setQueueData(response.data);
		} catch (err) {
			setError("Failed to fetch bed queue data");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const propertyId = current_booking?.property?.id;
		if (propertyId) {
			setSelectedProperty(propertyId);
			fetchBedQueueInfo(propertyId);
		}
	}, [current_booking]);

	const handlePropertyChange = (e) => {
		const propertyId = e.target.value;
		setSelectedProperty(propertyId);
		if (propertyId) {
			fetchBedQueueInfo(propertyId);
		} else {
			setQueueData([]);
		}
	};

	const handleGenderChange = (e) => {
		setSelectedGender(e.target.value);
	};

	const getCount = (data, gender) => {
		if (!data) return 0;

		// Special handling for unallocated beds
		if (data.hasOwnProperty('male') && data.hasOwnProperty('female')) {
			if (gender === "1") return data.male || 0;
			if (gender === "2") return data.female || 0;
			return data.total || 0;
		}

		// For other categories (blue, black, pink)
		if (gender === "1") return data.male || 0;
		if (gender === "2") return data.female || 0;
		return data.total || 0;
	};

	const calculateRowTotal = (queue) => {
		if (!queue) return 0;

		const unallocatedCount = getCount(queue.unallocated, selectedGender);
		const blockedCount = queue.blocked?.total || 0;
		const genderSpecificTotal =
			getCount(queue.blue, selectedGender) +
			getCount(queue.black, selectedGender) +
			getCount(queue.pink, selectedGender);

		return unallocatedCount + blockedCount + genderSpecificTotal;
	};

	const calculateColumnTotal = (category) => {
		if (!queueData) return 0;

		return queueData.reduce((sum, queue) => {
			if (!queue) return sum;

			if (category === 'unallocated') {
				return sum + getCount(queue[category], selectedGender);
			}
			if (category === 'blocked') {
				return sum + (queue[category]?.total || 0);
			}
			return sum + getCount(queue[category], selectedGender);
		}, 0);
	};

	const autoAllocateBed = async () => {
		setLoading(true);
		try {
			await axios.post(`${process.env.REACT_APP_API_URL}auto_allocate_bed`, {
				booking_id: current_booking.id,
			});
			dispatch(getBooking(current_booking.id));
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const delinkBed = async () => {
		setLoading(true);
		try {
			await axios.post(`${process.env.REACT_APP_API_URL}delink_user_bed`, {
				booking_id: current_booking.id,
				user: current_staff,
				move_location: 0,
			});
			dispatch(getBooking(current_booking.id));
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const delinkUnit = async () => {
		try {
			await axios.post(`${process.env.REACT_APP_API_URL}delink_user_unit`, {
				booking_id: current_booking.id,
				user: current_staff,
				move_location: 0,
			});
			dispatch(getBooking(current_booking.id));
		} catch (error) {
			console.error(error);
		}
	};

	if (error) return <div className="p-4 text-center text-red-500">{error}</div>;

	return (
		<div className="w-full">
			<div className="space-y-2">
				{current_booking?.bed_id === 0 && current_booking?.unit_type?.id > 0 && (
					<CustomButton
						text="Auto Allocate Bed"
						styling="w-full"
						icon={faBed}
						onClick={autoAllocateBed}
					/>
				)}

				{(current_booking.waiting_unit_id > 0 || current_booking.unit_id > 0) &&
					!current_staff.building_manager && (
						<CustomButton
							text="De-link Unit"
							styling="w-full"
							icon={faBed}
							onClick={delinkUnit}
						/>
					)}

				{current_booking.student_type &&
					current_booking?.user?.profile?.institution_study &&
					!current_staff.building_manager && (
						<CustomButton
							text="Manual Bed Assignment"
							styling="w-full"
							icon={faBed}
							onClick={() => dispatch(openModal({
								modal_type: "ModalBedAssignment",
								modal_props: {
									current_booking,
									type: type,
									tag_id: new URL(window.location.href).searchParams.get("tag"),
								},
							}))}
						/>
					)}
			</div>

			<div className="text-center mt-4">
				{current_staff?.isSuper === 1 ? (
					<a
						className="text-blue-500 underline"
						href={`/properties/step2/${current_booking?.property_id}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						{current_booking?.property?.name}
					</a>
				) : (
					<p>{current_booking?.property?.name}</p>
				)}

				{current_booking?.floor_id > 0 && (
					<a
						className="text-blue-500 underline ml-2"
						href={`/properties/step3/${current_booking?.property_id}/${current_booking?.floor_id}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						View Floor
					</a>
				)}

				<p className="mt-2">
					{current_booking?.unit?.name} {" "}
					{current_booking?.waiting_bed_id > 0
						? `${current_booking?.waiting_bed?.name} or similar. (on waitlist)`
						: current_booking?.bed?.name
					}
				</p>
				<p>
					{current_booking?.unit_type?.name}
					{current_booking?.rates?.[current_booking.rates.length - 1]?.signed_lease_at &&
						!current_booking?.bed?.name && " Queue"}
				</p>
			</div>

			<div className="mt-6 border-t-2 pt-4">
				<div className="flex justify-between items-center mb-4">
					<div className="font-bold">BED QUEUE</div>
					<CustomButton
						text="ATLAS"
						onClick={() => dispatch(openModal({
							modal_type: "ModalATLASReport",
							modal_props: {booking_id: current_booking?.id},
						}))}
					/>
				</div>

				<div className="grid grid-cols-2 gap-4 mb-4">
					<select
						value={selectedProperty}
						onChange={handlePropertyChange}
						className="w-full rounded-lg border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
					>
						<option value="">Select Building</option>
						{properties?.map(p => (
							<option key={p.value} value={p.value}>{p.label}</option>
						))}
					</select>

					<select
						value={selectedGender}
						onChange={handleGenderChange}
						className="w-full rounded-lg border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
					>
						<option value="0">All Genders</option>
						<option value="1">Male</option>
						<option value="2">Female</option>
					</select>
				</div>

				{loading ? <div className="p-4 text-center">Loading...</div> : (
					<div className="overflow-x-auto">
						<table className="min-w-full divide-y divide-gray-300">
							<thead>
							<tr>
								<th className="px-2 py-3 text-left text-xs font-semibold text-gray-900">Unit Type</th>
								<th className="px-2 py-3 text-center text-xs font-semibold text-gray-900">U</th>
								<th className="px-2 py-3 text-center text-xs font-semibold text-gray-900">
									<div className="w-4 h-4 rounded-full mx-auto bg-red-500"/>
								</th>
								<th className="px-2 py-3 text-center text-xs font-semibold text-gray-900">
									<div className="w-4 h-4 rounded-full mx-auto bg-blue-500"/>
								</th>
								<th className="px-2 py-3 text-center text-xs font-semibold text-gray-900">
									<div className="w-4 h-4 rounded-full mx-auto bg-black"/>
								</th>
								<th className="px-2 py-3 text-center text-xs font-semibold text-gray-900">
									<div className="w-4 h-4 rounded-full mx-auto bg-pink-500"/>
								</th>
								<th className="px-2 py-3 text-center text-xs font-semibold text-gray-900">T</th>
							</tr>
							</thead>
							<tbody className="divide-y divide-gray-200">
							{queueData?.map((queue, index) => (
								<tr key={index}>
									<td className="px-2 py-2 text-xs text-gray-900">{queue?.unit_type}</td>
									<td className="px-2 py-2 text-xs text-center">
										{getCount(queue?.unallocated, selectedGender)}
									</td>
									<td className="px-2 py-2 text-xs text-center">{queue?.blocked?.total || 0}</td>
									<td className="px-2 py-2 text-xs text-center">
										{getCount(queue?.blue, selectedGender)}
									</td>
									<td className="px-2 py-2 text-xs text-center">
										{getCount(queue?.black, selectedGender)}
									</td>
									<td className="px-2 py-2 text-xs text-center">
										{getCount(queue?.pink, selectedGender)}
									</td>
									<td className="px-2 py-2 text-xs text-center">
										{calculateRowTotal(queue)}
									</td>
								</tr>
							))}

							<tr className="border-t-2 border-gray-400 font-bold">
								<td className="px-2 py-2 text-xs">TOTAL</td>
								<td className="px-2 py-2 text-xs text-center">
									{calculateColumnTotal('unallocated')}
								</td>
								<td className="px-2 py-2 text-xs text-center">
									{calculateColumnTotal('blocked')}
								</td>
								<td className="px-2 py-2 text-xs text-center">
									{calculateColumnTotal('blue')}
								</td>
								<td className="px-2 py-2 text-xs text-center">
									{calculateColumnTotal('black')}
								</td>
								<td className="px-2 py-2 text-xs text-center">
									{calculateColumnTotal('pink')}
								</td>
								<td className="px-2 py-2 text-xs text-center">
									{queueData?.reduce((sum, queue) => sum + calculateRowTotal(queue), 0) || 0}
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				)}
			</div>
		</div>
	);
}